import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import { adminDashboardDetails } from 'API/reporting'
import { getUserData } from 'API/user'
import axios from 'axios'
import { Link } from 'gatsby'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Row, Col, Card, Container, Figure, Jumbotron } from 'react-bootstrap'
import { PromiseType } from 'utility-types'
import './admin.scss'

const DashboardPage: React.FC = () => {
	const [userData, setUserData] = React.useState<PromiseType<ReturnType<typeof getUserData>>>()
	const [adminDashboard, setDashboardData] = React.useState([])
	const [quoteData, setQuoteData] = useState<{ quote: string; from: string }>()
	useEffect(() => {
		const run = async () => {
			const data = await handleApi(axios.get<{ quote: string; from: string }>(getUrl('quote')))
			if (data) {
				setQuoteData(data)
			}
		}
		run()
	}, [])

	React.useEffect(() => {
		const run = async () => {
			const userData = await getUserData()
			if (userData) {
				setUserData(userData)
			}
		}
		run()
	}, [])

	React.useEffect(() => {
		const run = async () => {
			const dashboardData = await adminDashboardDetails()
			if (dashboardData) {
				setDashboardData(dashboardData)
			}
		}
		run()
	}, [])

	const links = [
		{
			name: 'Courses',
			to: '/admin/course',
			details: 'View Courses and Add.',
		},
		{
			name: 'Users',
			to: '/admin/add/users',
			details: 'Add Users.',
		},
	]
	if (userData?.admin === true) {
		links.push(
			{
				name: 'Teacher Reporting',
				to: '/admin/reporting_teacher',
				details: 'Teacher Reports',
			},
			{
				name: 'Course Reporting',
				to: '/admin/reporting',
				details: 'Course Reports',
			},
			{
				name: 'Groups',
				to: '/admin/group',
				details: 'Groups.',
			},
			{
				name: 'Live Users',
				to: '/admin/liveusers',
				details: 'Active Users in last 5 mins',
			}
		)
	}

	return (
		<>
			<div>
				<nav className='navbar navbar-expand-lg navbar-dark bg-primary'>
					<div className='navbar-nav'>
						{links.map((elem) => (
							<>
								<Link className='nav-item nav-link active' to={`${elem.to}`}>
									{elem.name}
								</Link>
							</>
						))}
					</div>
				</nav>
				<div className='mt-4'>
					<section className='page-contain'>
						<div className='row'>
							{adminDashboard.map((data: any) => (
								<>
									<div className='col-sm-6 col-md-3 col-lg-3 mt-2'>
										<Link className='data-card' to={`${data.link}`}>
											<h3>{data.count}</h3>
											<h4>{data.name}</h4>
										</Link>
									</div>
								</>
							))}
						</div>
					</section>
				</div>
				{/* <h4>Quote of the Day</h4>
				<h5>{quoteData?.quote}</h5>
				<h6>
					{' '}
					<em> -{quoteData?.from}</em>
				</h6> */}
				{/* <div className='courses-page mx-2'>
					{
						<>
							<div className=' mt-4 mb-4'>
								<div className='row'>
									{links.map((elem) => (
										<>
											<Col lg={3} md={4}>
												<Link to={`${elem.to}`}>
													<Card className='classCard'>
														<Jumbotron
															className='classJumbotron px-0'
															style={{ backgroundImage: `url('/card.jpg')`, backgroundSize: 'cover' }}
														>
															<Container className='mt-2'>
																<Row className='align-items-center '>
																	<Col xs='10'>
																		<h4 className='subName text-light'>{elem.name}</h4>
																	</Col>

																	<Col xs={2}>
																		<a href='/classes' className='text-right text-white '>
																			<i className='bx bx-dots-vertical-rounded float-right'></i>
																		</a>
																	</Col>
																</Row>
																<Row className='align-items-center '>
																	<Col></Col>
																</Row>
															</Container>
															<Figure className=' float-right pr-3'>
																
															</Figure>
														</Jumbotron>
														<Card.Body>
															<Row>
																<Col>
																	<h4>{elem.details}</h4>
																	
																</Col>
															</Row>
														</Card.Body>
													</Card>
												</Link>
											</Col>
										</>
									))}
								</div>
							</div>
						</>
					}
				</div> */}
			</div>
		</>
	)
}

export default DashboardPage
